function CampaignModule(campaign, countdownElement, formatCountdown, formatDisplayEnd, showPopup) {
    var self = this;
    if (!showPopup)
        showPopup = false;
    self.popupCampaign = ko.observable(false);
    self.countdownElement = ko.observable(countdownElement ? countdownElement : '.countdown');
    self.endDate = ko.observable('');
    self.campaign = campaign;

    self.initCampaign = function () {
        var diffTime = 0, eventTime = 0, currentTime = 0;
        rest('GET', '/api/v2/campaigns/' + self.campaign).then(function (response) {
            if (response.success && response.data.campaign) {
                if (response.data.campaign.end_at) {
                    eventTime = moment(response.data.campaign.end_at)
                } else {
                    eventTime = moment(response.data.campaign.start_at).add(response.data.campaign.duration, 'd');
                }
                currentTime = moment();
                self.endDate(eventTime.format(formatDisplayEnd ? formatDisplayEnd : 'DD.MM.YY'));
                diffTime = eventTime.unix() - currentTime.unix();

                dateCountdown(diffTime, self.countdownElement(), formatCountdown ? formatCountdown : null);
                if (diffTime > 0) {
                    self.popupCampaign(true);
                    if (!getCookie('cateringPromo')) {
                        self.showCampaign();
                    }
                } else {
                    self.popupCampaign(false);
                }
            }
        });


    };

    self.showCampaign = function () {
        if (showPopup)
        //mostrare popup o modale
            setCookie('cateringPromo');
    };


    return self;
}
